<template>
	<router-view></router-view>
</template>

<script>
export default {
	name: 'App',
	components: {},
	data() {
		return {}
	},
	created() {},
	methods: {},
	mounted() {
	
	},
	beforeDestroy() {}
}
</script>

<style lang="less">
body {
	margin: 0;
	background: #f7f7f7;
}
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	
}
</style>
