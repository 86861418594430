<template>
	<div id="alert-component" :class="[ob.type]" v-if="show">
		<img v-if="ob.type === 'success'" src="../assets/success-svgrepo-com.svg" alt="success-svgrepo-com">
		<img v-if="ob.type === 'error'" src="../assets/error-svgrepo-com.svg" alt="error-svgrepo-com">
		<span>{{ ob.message || 'Something unexpected happened' }}</span>
	</div>
</template>

<script>
export default {
	name: 'AlertComponent',
	props: {},
	components: {},
	data() {
		return {
			show: false,
			ob: {
				type: '',
				message: ''
			}
		}
	},
	methods: {
		showAlert(data) {
			this.ob = data;
			this.show = true;
			setTimeout(() => {
				this.show = false;
				this.ob = {
					type: '',
					message: ''
				}
			}, 3000)
		}
	},
	mounted() {},
	beforeDestroy() {}
}
</script>

<style lang="less">
#alert-component {
	width: 240px;
	min-height: 40px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: nowrap;
	padding: 5px 15px;
	box-sizing: border-box;
	border-radius: 5px;
	position: fixed;
	top: 10px;
	right: 10px;
	z-index: 171;
	&.error {
		background: rgb(96,3,3);
		background: linear-gradient(90deg, rgba(96,3,3,1) 0%, rgba(40,40,40,1) 24%, rgba(40,40,40,1) 73%);
		span {
			color: #ffa194;
		}
	}
	&.success {
		background: rgb(9,124,0);
		background: linear-gradient(90deg, rgba(9,124,0,1) 0%, rgba(40,40,40,1) 24%, rgba(40,40,40,1) 73%);
		span {
			color: #6bbb65;
		}
	}
	img {
		width: 20px;
		height: 20px;
		margin-right: 15px;
	}
	span {
		font-size: 14px;
	}
}
</style>
