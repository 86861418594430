<template>
	<div id="editor-component-wrapper" ref="editor-component-wrapper">
		<div v-if="loading" class="loading">Loading...</div>
		<alert-component ref="alert-component"/>
		<!--HIDDEN BUTTON IS FOR LOSING FOCUS WHENEVER MOBILE USER HIDES KEYBOARD MANUALLY, ASK YURII KOZLOV IF NOT CLEAR-->
		<!--<button style="position: absolute; top: 0; left: 0; z-index: -1;" id="loseFocus">test</button>-->
		<div v-show="!loading" id="richEdit" :class="{'show': ls !== null && ls.topBar, 'readonly': ls !== null && ls.readonly}" ref="rich-edit"/>
		<div class="document-loading-error" v-if="error.length">
			{{ error || 'Something unexpected happened' }}
		</div>
	</div>
</template>

<script>
import {
	create,
	createOptions,
	RichEdit,
	Interval,
	ViewType,
	RichEditUnit,
	DocumentFormat,
	RibbonTab,
	RibbonTabType,
	HomeTabItemId,
	RibbonButtonItem
} from 'devexpress-richedit';
import 'devexpress-richedit/dist/pdfkit';
import axios from "axios";
import AlertComponent from "@/components/AlertComponent";

export default {
	name: 'EditorComponent',
	rich: RichEdit,
	props: {
		msg: String
	},
	components: {AlertComponent},
	data() {
		return {
			init: true,
			temp: null,
			options: null,
			query: null,
			ls: null,
			loading: true,
			error: '',
			loop: true,
			iterval: null
			//preview: 0
		}
	},
	created() {
		this.ls = JSON.parse(localStorage.getItem('ui_params'));
		this.query = this.$route.query;
		/*if (this.ls) {
			this.preview = this.ls.preview ? this.ls.preview : 0
		}*/
	},
	mounted() {
		let l1 = localStorage.getItem('document');
		//window.android = true;
		if (window.android) {
			console.log('WINDOW ANDROID')
			if (l1) {
				console.log('DOCUMENT FOUND AT FIRST')
				this.ls = JSON.parse(localStorage.getItem('ui_params'));
				this.getLSDocument();
				if (this.ls) {
					if (this.ls.readonly) {
						this.$refs['editor-component-wrapper'].onclick = function () {
							return false;
						};
					}
				}
			} else {
				console.log('DOCUMENT NOT FOUND')
				this.interval = setInterval(() => {
					// console.log('INTERVAL IS GOING');
					let l2 = localStorage.getItem('document');
					let l3 = localStorage.getItem('ui_params');
					console.log(l3);
					// console.log('DOCUMENT IN LS')
					//console.log(l2);
					if (l2) {
						console.log('DOCUMENT FOUNND')
						this.ls = JSON.parse(localStorage.getItem('ui_params'));
						this.getLSDocument();
						if (this.ls) {
							if (this.ls.readonly) {
								this.$refs['editor-component-wrapper'].onclick = function () {
									return false;
								};
							}
						}
						clearInterval(this.interval);
					} else {
						console.log('DOCUMNET  NOT FOUND IN LOOP')
					}
				}, 1000)
			}
		} else {
			console.log('WINDOW ANDROID FALSE')
			this.getLSDocument();
			if (this.ls) {
				if (this.ls.readonly) {
					this.$refs['editor-component-wrapper'].onclick = function () {
						return false;
					};
				}
			}
		}
	},
	methods: {
		test() {
			this.rich.exportToPdf('asdasd')
		},
		showAlert(data) {
			this.$refs['alert-component'].showAlert(data);
		},
		getLSDocument() {
			let ls1 = localStorage.getItem('document');
			if (ls1) {
				this.initEditor(this.ls.readonly).then(() => {
					const ds = new TextEncoder().encode(ls1);
					this.rich.openDocument(ds, 'DocumentName', DocumentFormat.Rtf);
					setTimeout(() => {
						this.loading = false;
						if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.keyboard) {
							window.webkit.messageHandlers.keyboard.postMessage({
								"message": 'OK'
							});
						}
						if (window.android) {
							window.android.keyboard(JSON.stringify({
								"message": 'OK'
							}));
						}
						
						console.log(document.querySelectorAll('[title="Undo"]')[0].classList.contains('dx-state-disabled'));
						// dx-state-disabled
						/*console.log(document.querySelectorAll('[title="Undo"]'));
						console.log(document.querySelectorAll('[title="Redo"]'));
						console.log(document.querySelectorAll('[title="Save"]'));
						document.querySelectorAll('[title="Save"]')[1].click();*/
					}, 500);
				})
			} else {
				/*this.initEditor(0).then(() => {
					// this.rich.openDocument('', 'DocumentName', DocumentFormat.Rtf);
				})*/
			}
		},
		saveDocument() {
			console.log(window.android);
			this.rich.exportToBase64((file) => {
				const decoded = atob(file);
				let st = JSON.stringify(decoded);
				if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.textContentSaved) {
					window.webkit.messageHandlers.textContentSaved.postMessage({
						"message": st
					});
				}
				if (window.android) {
					window.android.textContentSaved(JSON.stringify({
						"message": st
					}));
					window.android.textContentSaved1(st);
				}
			}, DocumentFormat.Rtf)
		},
		
		initEditor(readonly) {
			// the createOptions() method creates an object that contains RichEdit options initialized with default values
			return new Promise((resolve) => {
				this.options = createOptions();
				this.options.view.printLayoutViewSettings.showHorizontalRuler = false;
				// this.options.showHorizontalRuler = false;
				this.options.bookmarks.visibility = true;
				this.options.bookmarks.color = '#ff0000';
				this.options.confirmOnLosingChanges.enabled = true;
				this.options.confirmOnLosingChanges.message = 'Are you sure you want to perform the action? All unsaved document data will be lost.';
				this.options.fields.updateFieldsBeforePrint = true;
				this.options.fields.updateFieldsOnPaste = true;
				this.options.mailMerge.activeRecord = 2;
				this.options.mailMerge.viewMergedData = true;
				this.options.mailMerge.dataSource = [
					{Name: 'Indy', age: 32},
					{Name: 'Andy', age: 28},
				];
				
				//added
				this.options.contextMenu.enabled = false
				
				// events
				this.options.events.activeSubDocumentChanged = () => {};
				this.options.events.autoCorrect = () => { };
				this.options.events.calculateDocumentVariable = () => {};
				this.options.events.characterPropertiesChanged = () => { };
				this.options.events.contentInserted = () => { };
				this.options.events.contentRemoved = () => { };
				this.options.events.documentChanged = () => {
					this.rich.exportToBase64((file) => {
						const decoded = atob(file);
						localStorage.setItem('document', `${decoded}`);
						if (window.android) {
							window.android.onDocumentChanged(decoded);
						}
					}, DocumentFormat.Rtf);
					
					if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.documentChanged) {
						window.webkit.messageHandlers.documentChanged.postMessage({
							"undoButtonEnabled": !document.querySelectorAll('[title="Undo"]')[0].classList.contains('dx-state-disabled'),
							"redoButtonEnabled": !document.querySelectorAll('[title="Redo"]')[0].classList.contains('dx-state-disabled'),
						});
					}
					if (window.android) {
						window.android.documentChanged(JSON.stringify({
							"undoButtonEnabled": !document.querySelectorAll('[title="Undo"]')[0].classList.contains('dx-state-disabled'),
							"redoButtonEnabled": !document.querySelectorAll('[title="Redo"]')[0].classList.contains('dx-state-disabled'),
						}));
					}
				};
				this.options.events.documentFormatted = (s, e) => {
					//const t = document.getElementsByClassName('dxreInputTarget')[0];
					//console.log(t);
					// const tt = t.contentWindow.document.getElementsByTagName('body')[0];
					// tt.setAttribute('spellcheck', false)
					document.getElementsByClassName('dxrePages')[0].style.height = (document.getElementsByClassName('dxrePages')[0].scrollHeight + 2) + 'px';
					if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.pageHeight) {
						window.webkit.messageHandlers.pageHeight.postMessage({
							"message": document.getElementsByClassName('dxrePages')[0].scrollHeight,
							"message1": document.getElementById('_View').offsetHeight,
							"pageCount": e.pageCount,
							"pageHeight": document.getElementsByClassName('dxrePage')[0].scrollHeight,
							"containerWidth": document.getElementsByClassName('dxrePages')[0].offsetWidth,
							"pageWidth": document.getElementsByClassName('dxrePage')[0].clientWidth
						});
					}
					if (window.android) {
						window.android.pageHeight(JSON.stringify({
							"documentHeight": document.getElementsByClassName('dxrePages')[0].scrollHeight,
							"wrapperHeight": document.getElementById('_View').offsetHeight,
							"pageCount": e.pageCount,
							"pageHeight": document.getElementsByClassName('dxrePage')[0].scrollHeight,
							"containerWidth": document.getElementsByClassName('dxrePages')[0].offsetWidth,
							"pageWidth": document.getElementsByClassName('dxrePage')[0].clientWidth
						}));
					}
					
					setTimeout(() => {
						const q = document.getElementById('_View');
						const qq = document.getElementById('editor-component-wrapper');
						qq.style.height = (document.getElementById('_View').offsetHeight-2) + 'px';
						q.style.height = (document.getElementById('_View').offsetHeight-2) + 'px';
						qq.scrollTop = 1;
						q.scrollTop = 1;
						setTimeout(() => {
							qq.scrollTop = 0;
							q.scrollTop = 0;
							if (this.ls.readonly === 1) {
								q.style.overflow = 'unset';
							}
							
							/*setTimeout(() => {
								document.getElementById('loseFocus').focus();
							},5000)*/
						}, 0.01);
					}, 250);
				};
				this.options.events.documentLoaded = () => {};
				this.options.events.onPdfExporting = () => {};
				this.options.events.gotFocus = () => {};
				this.options.events.hyperlinkClick = () => { };
				this.options.events.keyDown = () => { };
				this.options.events.keyUp = (s, e) => {
					console.log(s);
					console.log(e);
					if (window.android) {
						const ke = new KeyboardEvent('keydown', {
							bubbles: true, cancelable: true, keyCode: 8
						});
						const ke1 = new KeyboardEvent('keydown', {
							bubbles: true, cancelable: true, keyCode: 13
						});
						const t = document.getElementsByClassName('dxreInputTarget')[0];
						t.setAttribute('spellcheck', false)
						if (e.htmlEvent.target.outerHTML.includes('<div><br></div>')) {
							t.dispatchEvent(ke);
							t.dispatchEvent(ke1);
						}
					}
				};
				this.options.events.paragraphPropertiesChanged = () => { };
				this.options.events.lostFocus = () => { };
				this.options.events.pointerDown = () => {};
				this.options.events.pointerUp = (s,e) => {
					if (!this.ls.readonly) {
						if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.keyboard) {
							window.webkit.messageHandlers.keyboard.postMessage({
								"message": 'OK'
							});
						}
						if (window.android) {
							window.android.keyboard(JSON.stringify({
								"message": 'OK'
							}));
						}
					}
				};
				this.options.events.saving = (s, e) => {
					e.handled = true;
				};
				this.options.events.saved = () => { };
				this.options.events.selectionChanged = () => { };
				this.options.events.customCommandExecuted = (s, e) => {
					switch (e.commandName) {
						case 'insertEmailSignature':
							s.document.insertParagraph(s.document.length);
							s.document.insertText(s.document.length, '_________');
							s.document.insertParagraph(s.document.length);
							s.document.insertText(s.document.length, 'Best regards,');
							s.document.insertParagraph(s.document.length);
							s.document.insertText(s.document.length, 'John Smith');
							s.document.insertParagraph(s.document.length);
							s.document.insertText(s.document.length, 'john@example.com');
							s.document.insertParagraph(s.document.length);
							s.document.insertText(s.document.length, '+1 (818) 844-0000');
							break;
					}
				};
				this.options.unit = RichEditUnit.Inch;
				// this.options.view.viewType = ViewType.Simple;
				this.options.view.viewType = readonly ? ViewType.PrintLayout : ViewType.Simple;
				this.options.view.simpleViewSettings.paddings = {
					left: 15,
					top: 15,
					right: 15,
					bottom: 15,
				};
				this.options.autoCorrect = {
					correctTwoInitialCapitals: true,
					detectUrls: true,
					enableAutomaticNumbering: true,
					replaceTextAsYouType: true,
					caseSensitiveReplacement: false,
					replaceInfoCollection: [
						{replace: "wnwd", with: "well-nourished, well-developed"},
						{replace: "(c)", with: "©"}
					],
				};
				// capitalize the first letter at the beginning of a new sentence/line
				this.options.events.autoCorrect = function (s, e) {
					if (e.text.length == 1 && /\w/.test(e.text)) {
						var prevText = s.document.getText(new Interval(e.interval.start - 2, 2));
						if (prevText.length == 0 || /^([.]|[?]|[!] )$/.test(prevText) || prevText.charCodeAt(1) == 13) {
							var newText = e.text.toUpperCase();
							if (newText != e.text) {
								s.beginUpdate();
								s.history.beginTransaction();
								s.document.deleteText(e.interval);
								s.document.insertText(e.interval.start, newText);
								s.history.endTransaction();
								s.endUpdate();
								e.handled = true;
							}
						}
					}
				};
				this.options.exportUrl = 'https://siteurl.com/api/';
				/*if (!readonly && this.preview) {
					this.options.readOnly = true;
				} else {
					this.options.readOnly = readonly;
				}*/
				this.options.readOnly = readonly;
				// this.options.readOnly = false;
				this.options.width = (window.innerWidth) + 'px';//'1400px';
				this.options.height = (window.innerHeight) + 'px';//'800px';
				var ribbonButton = new RibbonButtonItem("testSave", "Save",
						{icon: "save", showText: true, beginGroup: true});
				let arr = this.options.ribbon.getTab(RibbonTabType.Home).items.filter((o, index) => {if (index > 1) {return o.id}});
				arr.forEach(o => {
					this.options.ribbon.getTab(RibbonTabType.Home).removeItem(o);
				});
				this.options.ribbon.getTab(RibbonTabType.Home).insertItem(ribbonButton, 2);
				this.options.events.customCommandExecuted = (s, e) => {
					switch (e.commandName) {
						case 'testSave':
							this.saveDocument();
							break;
					}
				}
				this.options.ribbon.visible = true;
				// this.options.ribbon.visible = this.ls.topBar;
				this.options.ribbon.activeTabIndex = 1;
				this.options.ribbon.removeTab(10);
				
				/*this.options.fonts = {
					defaultFolder: 'fontsFolder',
					fonts: [
						{
							name: 'Calibri',
							fontFamily: 'Calibri',
							regularFontUri: '../assets/CalibriFont/calibri.ttf',
							italicFontUri: '../assets/CalibriFont/calibrii.ttf',
							boldFontUri: '../assets/CalibriFont/calibrib.ttf',
							boldItalicFontUri: '../assets/CalibriFont/calibriz.ttf',
						}
					],
					mappings: {
						defaultFontName: 'Calibri',
					}
				};*/
				
				var richElement = document.getElementById("richEdit");
				this.rich = create(richElement, this.options);
				/*const ds = new TextEncoder().encode(`{\\rtf1\\ansi\\deff3\\adeflang1025
{\\fonttbl{\\f0\\froman\\fprq2\\fcharset0 Times New Roman;}{\\f1\\froman\\fprq2\\fcharset2 Symbol;}{\\f2\\fswiss\\fprq2\\fcharset0 Arial;}{\\f3\\froman\\fprq2\\fcharset0 Liberation Serif{\\*\\falt Times New Roman};}{\\f4\\fswiss\\fprq2\\fcharset0 Liberation Sans{\\*\\falt Arial};}{\\f5\\fmodern\\fprq1\\fcharset0 Liberation Mono{\\*\\falt Courier New};}{\\f6\\fnil\\fprq2\\fcharset0 AR PL SungtiL GB;}{\\f7\\fswiss\\fprq0\\fcharset128 Noto Sans Devanagari;}{\\f8\\fnil\\fprq2\\fcharset0 Noto Sans Devanagari;}}
{\\colortbl;\\red0\\green0\\blue0;\\red0\\green0\\blue255;\\red0\\green255\\blue255;\\red0\\green255\\blue0;\\red255\\green0\\blue255;\\red255\\green0\\blue0;\\red255\\green255\\blue0;\\red255\\green255\\blue255;\\red0\\green0\\blue128;\\red0\\green128\\blue128;\\red0\\green128\\blue0;\\red128\\green0\\blue128;\\red128\\green0\\blue0;\\red128\\green128\\blue0;\\red128\\green128\\blue128;\\red192\\green192\\blue192;}
{\\stylesheet{\\s0\\snext0\\rtlch\\af8\\afs24\\alang1081 \\ltrch\\lang1033\\langfe2052\\hich\\af3\\loch\\nowidctlpar\\hyphpar0\\ltrpar\\cf0\\f3\\fs24\\lang1033\\dbch\\af6\\langfe2052 Normal;}
{\\s15\\sbasedon0\\snext16\\rtlch\\af8\\afs28 \\ltrch\\hich\\af4\\loch\\sb240\\sa120\\keepn\\f4\\fs28\\dbch\\af6 Heading;}
{\\s16\\sbasedon0\\snext16\\loch\\sl276\\slmult1\\sb0\\sa140 Text Body;}
{\\s17\\sbasedon16\\snext17\\rtlch\\af7 \\ltrch\\loch\\sl276\\slmult1\\sb0\\sa140 List;}
{\\s18\\sbasedon0\\snext18\\rtlch\\af7\\afs24\\ai \\ltrch\\loch\\sb120\\sa120\\noline\\fs24\\i Caption;}
{\\s19\\sbasedon0\\snext19\\rtlch\\af7\\alang255 \\ltrch\\lang255\\langfe255\\loch\\noline\\lang255\\dbch\\langfe255 Index;}
{\\s20\\sbasedon0\\snext20\\rtlch\\af5\\afs20 \\ltrch\\hich\\af5\\loch\\sb0\\sa0\\f5\\fs20\\dbch\\af5 Preformatted Text;}
}{\\*\\generator LibreOffice/7.3.5.2$Linux_X86_64 LibreOffice_project/30$Build-2}{\\info{\\creatim\\yr0\\mo0\\dy0\\hr0\\min0}{\\revtim\\yr0\\mo0\\dy0\\hr0\\min0}{\\printim\\yr0\\mo0\\dy0\\hr0\\min0}}{\\*\\userprops}\\deftab709
\\hyphauto1\\viewscale100
{\\*\\pgdsctbl
{\\pgdsc0\\pgdscuse451\\pgwsxn11906\\pghsxn16838\\marglsxn1134\\margrsxn1134\\margtsxn1134\\margbsxn1134\\pgdscnxt0 Default Page Style;}}
\\formshade\\paperh16838\\paperw11906\\margl1134\\margr1134\\margt1134\\margb1134\\sectd\\sbknone\\pgndec\\sftnnar\\saftnnrlc\\sectunlocked1\\pgwsxn11906\\pghsxn16838\\marglsxn1134\\margrsxn1134\\margtsxn1134\\margbsxn1134\\ftnbj\\ftnstart1\\ftnrstcont\\ftnnar\\aenddoc\\aftnrstcont\\aftnstart1\\aftnnrlc
{\\*\\ftnsep\\chftnsep}\\pgndec\\pard\\plain \\s20\\rtlch\\af5\\afs20 \\ltrch\\hich\\af5\\loch\\sb0\\sa0\\f5\\fs20\\dbch\\af5\\ql\\sb0\\sa0\\ltrpar{\\loch
sdfsdfsdf}
\\par }`);*/
				// this.rich.openDocument(ds, 'DocumentName', DocumentFormat.Rtf);
				this.rich.updateRibbon(function (ribbon) {
					ribbon.activeTabIndex = 1;
				});
				
				resolve();
			})
		}
	},
	
	beforeDestroy() {
		if (this.rich) {
			this.rich.dispose();
			this.rich = null;
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
#editor-component-wrapper {
	// overflow: scroll;
}
.document-loading-error {
	position: fixed;
	width: 300px;
	height: 200px;
	top: 50%;
	left: 50%;
	margin-top: -100px; /* Negative half of height. */
	margin-left: -150px;
}

.loading {
	position: fixed;
	width: 300px;
	height: 200px;
	top: 50%;
	left: 50%;
	margin-top: -100px;
	margin-left: -150px;
	font-size: 20px;
}

:deep(#richEdit) {
	border-width: 0;
	.dxrePage {
		margin: 0 auto;
	}
	/*.dxreView {
		overflow: unset;
	}*/
	&.readonly {
		.dxreView {
			.dxrePages {
				pointer-events: none;
			}
			
			.dxreInputTarget {
				display: none;
				pointer-events: none;
			}
		}
	}
	
	&.show {
		.dx-ribbon {
			.dx-tabpanel-container {
				display: block;
			}
		}
	}
	
	.dx-ribbon {
		.dx-tabpanel-tabs {
			display: none !important;
		}
		
		.dx-tabpanel-container {
			padding: 0 !important;
			margin: 0 !important;
			display: none;
		}
	}
}

</style>
