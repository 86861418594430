import {createRouter, createWebHistory, createWebHashHistory} from 'vue-router';
import EditorComponent from "@/components/EditorComponent";

const routes = [
	{
		path: '/',
		component: EditorComponent
	}
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

router.beforeEach((to, from, next) => {
	const ls = JSON.parse(localStorage.getItem('ui_params'));
	console.log(ls);
	if (ls !== null && ls.mobileScale /*!ls.readonly && !ls.preview*/) {
		var meta = document.createElement('meta');
		meta.name = "viewport";
		meta.content = "width=device-width,initial-scale=1.0";
		document.getElementsByTagName('head')[0].appendChild(meta);
	}/*if (!parseInt(to.query.readonly) && !to.query.preview) {
		var meta = document.createElement('meta');
		meta.name = "viewport";
		meta.content = "width=device-width,initial-scale=1.0";
		document.getElementsByTagName('head')[0].appendChild(meta);
	}*/
	
	next();
	
});

export default router
